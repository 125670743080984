@import '../../Theme';

$locationWidth: 500px;

.sidebarMenuLocation {

  position: relative;

  &--mask {
    display: block;
    background: rgba(0, 0, 0, .3);
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
  }

  &--dropdown {

    .btn-close {
      z-index: 25;
      position: absolute;
      width: 20px;
      height: 20px;
      margin: 0;
      right: 2px;
      padding: 3px 0 0 3px;
      border-radius: 50px;
      border: 1px solid var(--app-color-light-shade);
      background-color: var(--app-color-light-bright);
    }

    .modal {
      position: fixed;
      z-index: 20;
      width: $locationWidth;
      max-width: calc(100vw - 12vw);
      min-height: 480px;
      height: calc(100% - 350px);
      top : 480px;
      background-color: var(--app-color-light-bright);
      padding: 20px;
      left: 280px;
      border-radius: 5px;
      text-align: left;
      box-shadow: 1px 11px 78px rgba(0, 0, 0, .4);
    }

    .multiselect-container {

      .option {
        text-wrap: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: keep-all;
        width: $locationWidth;

        &:hover, &.highlight {
          background-color: var(--app-color-primary-shade);
        }
      }

      .search-wrapper {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 10px;
        margin-top: 10px;
        min-height: 100px;
        max-height: 255px;
        overflow-y: scroll !important;

        span:first-child {
          margin-top: 80px;
        }

        span {
          background-color: var(--app-color-primary-shade);
        }

        .searchBox {
          margin: 0;
          border: 1px solid var(--app-color-light-shade);
          max-width: 400px;
          left: 2.5%;
          top: 10%;
          position: absolute;
        }
      }

      .optionListContainer {
        display: block;
        width: $locationWidth;
        height: auto;
        background-color: var(--app-color-light-bright);
        padding: 0;
        position: relative;
        margin-top: 10px;
        .checkbox {
          display: inline-block;
          width: 20px;
        }

        input[type="checkbox"]:checked {
          background-color: red !important;
        }
      }
    }

  }
}


@media only screen and (max-width: $breakpoint-sm) {
  .sidebarMenuLocation--dropdown {
    margin: 0 10px;

    .modal {
      display: block;
      position: fixed;
      z-index: 20;
      width: 100vw;
      max-width: calc(100vw - 12vw);
      height: 100%;
      background-color: var(--app-color-light-bright);
      padding: 20px;
      bottom: 0px;
      top: 0;
      left: 0;
      border-radius: 5px;
      text-align: left;
    }

    .btn-close {
      right: 0px;
      top: 0px;
      left: unset;
    }

    .multiselect-container {
      max-width: calc(100vw - 12vw);
      box-shadow: none;
      min-height: auto;
      border-radius: 0;

      .search-wrapper span {
        text-overflow: ellipsis;
        width: 70vw;
        overflow: hidden;
      }

      .option {
        max-width: calc(100vw - 15vw);
        display: block;
        text-overflow: ellipsis;
        width: 80vw;
        overflow: hidden;
      }

      .optionListContainer {
        max-width: calc(100vw - 12vw);

        .checkbox {
          width: 20px;
          padding: 0;
          margin: 0 5px 0 0;
        }
      }
    }
  }
}