.reports-page-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    overflow-y: overlay;
}
.reports-page-wrapper label{
    display: initial;
    font-weight: initial;
    margin-bottom: initial;
    text-align: initial;
}
.reports-page-wrapper span, .reports-page-wrapper p{
    text-align: initial;
}
.reports-page-wrapper .tabs-list-option, .reports-page-wrapper .loading-error, .reports-page-wrapper .date-picker-text{
    text-align: center;
}
.reports-page-wrapper input, .reports-page-wrapper select, .reports-page-wrapper label{
    border: initial;
    border-radius: initial;
    box-sizing: initial;
    font-weight: initial;
    padding: initial;
    outline: initial;
    width: initial;
}
.reports-page-wrapper input::placeholder{
    font-weight: initial;
}
.select-filter-container input{
    width: 100%;
    padding: 1px 5px;
}