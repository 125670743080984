@import '../../Theme';

.card--skeletons {
  .card {
    opacity: .4;

    span {
      width: 100%;
      height: 10px;
      background: var(--app-color-light-shade);
      display: block;
      border-radius: 10px;
      margin-bottom: 10px;
      margin-top: 10px;

    }

    :nth-child(2) {
      width: 70%;
    }

    &:hover {
      background: none;
    }
  }
}


