.form-view-commands {
	text-align: right;

	.MuiButtonBase-root {
		color: #327dc6;
	}
}

.form-view-cont {
	background-color: #e0e0e0;
	padding: 10px;

	.form-view-doc {	
		display: flex;	
		flex: 1;
		padding: 10px;
		background-color: white;

		#formio-result {			
			flex: 1;
			padding: 10px;
		}
	}

	.container {
		text-align: left;
	}
}
