

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9000;
}

.modal {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    max-height: 70vh;
    position: fixed;
    background: #FFF;
    padding: 1.75rem;
    z-index: 9001;
    border-radius: 1.5rem;
    width: 700px;
    height: 275px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    select {
        font: inherit;
        padding: 10.5px 32px 10.5px 14px;
    }

    .modal--label {
        transform: translate(0, 1.5px) scale(0.75);
        margin-bottom: 0;
    }

    .modal__close {
        position: absolute;
        top: 10%;
        left: 95%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background: #DFDEDF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 0.5;
        cursor: pointer;
    }

    .modal__content {
        height: 100%;
        flex: 1;
    }

    .modal__content--title {
        display: flex;
        flex-direction: row;
        align-content: center;

        h2 {
            display: inline-block;
            margin: 0;
            margin-left: 0.5rem;
            padding-top: 0.25rem;
            font-family: "Open Sans", sans-serif;
            user-select: none;
        }

        svg {
            padding: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: #235789;
            box-shadow: 0 4px 0 #143B7A;
        }
    }

    .modal__content--body {
        user-select: none;
        margin: 2em 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        p {
            cursor: pointer;
            margin: 0.5em 0 0 0.5em;
            font-weight: 600;
            color: #235789;
        }
    }

    .modal__content--button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

