.headline-small {
  text-align: left;
  padding-left: 25px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 10px;
}

.txt {
  &-light {
    font-size: 1rem;
    color: var(--app-color-medium);
  }

  &--small {
    font-size: .8rem;
  }

  &--caps {
    text-transform: capitalize;
  }

  &--alert {
    color: var(--app-color-danger);

    svg {
      top: 2px;
      position: relative;
    }
  }

}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}
