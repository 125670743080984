@import '../../Theme';
@import '../TwoWayEmoji/TwoWayEmoji.scss';

.attachments-grid {
  grid-template-columns: repeat(auto-fill, 45px);
  grid-template-rows: repeat(auto-fill, 45px);
  height: 200px;
  overflow: scroll;

  .image-card--holder {
    .card--image {
      cursor: pointer;
    }
  }
}

.attachment {
  &--sheet {
    @include sheet(70px);
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .attachment {
    &--sheet {
      @include sheet(50px);
      left: 0;
      margin-left: -133px;

      &::before {
        right: 20px;
      }
    }
  }
}