@import "../../Theme";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans");

$breakpoint-xs: 750px;
$bodyHeight: calc(var(--vh) * 50);

.field-required:after,
.tab-error::after {
	display: none;
}

#formio-result {
	input, select, textarea {
		font-weight: normal;
	}
}

.test{
	margin-left:40px;
	margin-bottom: 5px;
}
.registrationsection {
	font-family: "Open Sans", sans-serif;
	font-style: normal;
	display: flex;
	flex: 1;
	text-align: left;

	.registration-forms {
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow: auto;

		// .MuiButtonBase-root {
		// 	color: #327dc6!important;
		// }

		.tabs-cont {
			display: flex;
			flex: 1;
			flex-direction: column;

			.tab-heads-cont {
				padding-left: 20px;
			}
	
			.pi-tabpanel {
				padding: 20px;
	
				.form-status {	
					display: flex;
					flex: 1;
					flex-direction: column;

					.form-view-commands {
						text-align: right;
					}

					.form-view-cont {
						display: flex;
						flex: 1 100%;
					}
	
					.view-header {
						font-family: "Open Sans",sans-serif;
						font-style: normal;
						font-weight: 600;
						line-height: 33px;

						.registration-forms-filter {
							display: flex;
							flex-direction: column;
							row-gap: 10px;

							> * {
								display: inline-block;
								margin-right: 10px;
								margin-bottom: 5px;
						
								* {
									text-align: initial;
								}
							}

							.date-range {
								display: flex;
								gap: 10px;
								flex-wrap: wrap;
								white-space: nowrap;

								.date-range-fields {
									display: flex;
									flex-wrap: wrap;
									gap: 10px;

									> div {
										width: 140px;
										margin-right: 10px;
									}
								}
							}

							.filterby {
								display: flex;
								gap: 10px;
								flex-wrap: wrap;

								.search {
									max-width: 300px;
								}
							}
						}
					}
		
					.view-body {
						position: relative;
						margin-top: 10px;
					
						.actions-column {
							.InovuaReactDataGrid__cell__content {
								text-align: center;
					
								> * {
									display: inline-block;
									margin-right: 10px;
									padding: 0;
									float: none;
									vertical-align: middle;
									color: #327dc6;
								}
							}
						}
					
						.selection-commands {
							text-align: right;
							> * {
								color: #327dc6;
								font-size: 16px;
								text-decoration: none;
								text-transform: initial;
							}
						}
					}
				}

				.patient-forms {
					display: flex;
					flex: 1;
					flex-direction: column;

					.actions-column {
						text-align: center;

						a {
							display: inline-block;
							margin: 0 10px 5px 0;
							color: #327dc6;
						}
					}
				}
			}
		}		
	}
}

#registration-forms-workspace {
	display: none;
	height: 400px;
}

@media only screen and (max-width: $breakpoint-xs) {
	.filterby {
		.select {
			width: 100% !important;
		}
		.search {
			max-width: 195px !important;
		}
	}

	#portal {
		.modal {
			width: calc(100% - 80px);
		}
	}
}