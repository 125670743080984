@import '../../Theme';

.message--conversation--patient {
  padding: 12px;
  top:156px;
  border-bottom: 1px solid var(--app-color-light-shade);
  width:100%;
  z-index: 1;
  background-color: var(--app-color-light);
  p {
    margin: .5em 0;
    font-size: 14px;
  }
}
.message--conversation--patient--btn-remove {
  margin:20px;
}

.ril-outer.ril__outer.ril__outerAnimating {
  background-color: rgba(0, 0, 0, 0.03);
  animation: none;
}

@media only screen and (max-width: $breakpoint-sm) {
  .message--conversation--patient {
    top: initial;
    bottom:145px;
  }
}

