$default-border-color: #c9c9c9;

.tab-head {
    cursor: pointer;
    position: relative;
    padding: 4px 15px 0 15px;

    .main { 
        color: $default-border-color;
        border-top-left-radius: 17px;
        border-top-right-radius: 17px;
        box-shadow: 0px -2px 4px -1px $default-border-color;
        padding: 5px 10px;
        min-width: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .extra {
        position: absolute;
        bottom: 0;
        width: 15px;
        height: 21px;

        > div {
            margin-top: -1px;
        }
    }

    .extra.bf {
        left: 0;

        > div {                        
            border-bottom-right-radius: 12px;
            box-shadow: inset -1px -1px 2px -1px $default-border-color;
        }
    }

    .extra.af {
        right: 0;

        > div {                        
            border-bottom-left-radius: 12px;
            box-shadow: inset 1px -1px 2px -1px $default-border-color;
        }
    }
}