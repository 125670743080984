@import "../../Theme/variables";

.MuiToolbar-root,
.MuiToolbar-root:hover {
	background-color: #03122c;

	img {
		width: 120px;
	}
}

.logo {
	position: relative;
	max-width: 249px;
	height: 29px;

	font-family: "Open Sans", sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 38px;
	display: flex;
	align-items: center;
	letter-spacing: 0.8px;

	color: #ffffff;
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
	h1 {
		width: auto;
		overflow: hidden;
		font-size: 100%;
		box-sizing: border-box;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		font-weight: 600;
		line-height: 1.5;
		padding-top: 6px;
		white-space: nowrap;
		letter-spacing: 0.00938em;
		padding-bottom: 6px;
		padding-left: 16px;
		margin: 0;
		padding-right: 16px;
	}
}

.MuiButtonBase-root:not(.MuiTabs-scroller button) {
	&:hover {
		background-color: var(--app-color-primary-shade);
	}
}

.logout-container {
	display: flex;
	justify-content: center;
}

.divider {
	content: "";
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}

.Appbar {
	.datetime {
		text-align: right;
		white-space: nowrap;

		svg {
			position: relative;
			top: 2px;
			left: 3px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.Appbar {
		.datetime {
			position:absolute;
			top: 0px;
			height:10px;
			width:20px;
			right: 100%;
			font-size: 13px;
		}
	}
 }
 
.account {
	margin-left: 20px;
	display: flex;
	cursor: pointer;

	svg {
		position: relative;
		width: 16px;
		top: 1px;
	}

	svg:nth-child(2) {
		align-self: center;
		top: 0px;
	}
}

@media only screen and (max-width: $breakpoint-xs) {
	.Appbar {
		.datetime {
			display: none;
		}
	}
	.account {
		display: none;
	}
	.topnav {
		display: none !important;
	}
}

.topnav {
	position: absolute;
	overflow: hidden;
	margin-left: 300px;
	top: -18px !important;
	width: 100%;
	display: flex;
}

.topnav ul {
	height: 65px;
}

.topnav ul li {
	position: relative;
	cursor: pointer;
	list-style: none;
	float: left;
	color: #f2f2f2;
	border-top-left-radius: 17px;
	border-top-right-radius: 17px;
	text-align: center;
	margin-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
	padding-top: 19px;
	padding-bottom: 19px;
	width: 144px;
	text-decoration: none;
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 17px;
	font-style: "semibold";
	line-height: 22px;
}

.topnav ul li:hover {
	//background-color: #ddd;
	font-size: 20px;
	color: white;
}

.topnav ul li.active {
	background-color: #235789;
	color: white;
}

.topnav ul li.active b:nth-child(1) {
	position: absolute;
	// left: 35px !important;
	left: -20px !important;
	height: 41px;
	width: 20px;
	background: #235789;
}

.topnav ul li.active b:nth-child(1)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-bottom-right-radius: 20px;
	background: #03122c;
}

.topnav ul li.active b:nth-child(2) {
	position: absolute;
	right: -20px !important;
	height: 41px;
	width: 20px;
	background: #235789;
}

.topnav ul li.active b:nth-child(2)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-bottom-left-radius: 20px;
	background: #03122c;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
	display: none;
	position: fixed;
	margin-top: 10px;
	background-color: white;
	min-width: 180px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	font-family: "Open Sans", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	align-items: center;
	z-index: 1;
}

.dropdown-content a {
	float: none;
	color: #235789;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

/* Show the dropdown menu on hover */
.topnav ul li:hover .dropdown-content {
	display: block;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.Appbar {
	.tabs-top-menu {
		margin-left: 120px;

		ul {
			li {				
				border: 1px solid #235789;

				b:nth-child(1)::before {
					left: -1px !important;
					border-right: 1px solid #235789;
				}
				
				b:nth-child(2)::before {
					border-left: 1px solid #235789;
				}
			}

			li:not(:last-child) {
				b:nth-child(1)::before {
					left: -1px !important;
					border-right: 1px solid #235789;
				}
			}

			li.list {
				background-color: #03122c;
				color: white;
			}

			li.active {
				border: 1px solid #327dc6;
				background-color: #327dc6;
				color: white;

				b::before {
					background-color: #03122c;
				}

				b:nth-child(1)::before {
					left: -1px !important;
					border-right: 1px solid #327dc6;
				}
				
				b:nth-child(2)::before {
					border-left: 1px solid #327dc6;
				}
			}
		}
	}
}


.tabs-top-menu {
	width: 100%;

	position: relative;

	> .MuiBox-root {
		padding: 10px;
	}

	.MuiAppBar-colorPrimary,
	.MuiToolbar-root,
	.MuiToolbar-root:hover {
		background-color: initial !important;
	}

	.MuiAppBar-root,
	.MuiToolbar-root {
		padding: 0;
	}

	.MuiToolbar-regular {
		min-height: initial !important;
	}

	ul {
		list-style: none;
		margin-bottom: 0;
		padding: 0;

		li {
			margin-left: 10px !important;
			margin-right: 20px !important;
			position: relative;
			cursor: pointer;
			list-style: none;
			float: left;
			border-top-left-radius: 17px;
			border-top-right-radius: 17px;
			text-align: center;
			margin-top: 5px;
			margin-left: 5px;
			margin-right: 5px;
			padding-top: 10px;
			padding-bottom: 10px;
			width: 120px;
			text-decoration: none;
			font-family: "Open Sans", sans-serif;
			font-weight: bold;
			font-size: 10pt;
			font-style: "semibold";
			line-height: 22px;
			white-space: nowrap;
			//box-shadow: 0px -4px 4px -1px #c9c9c9;

			b {
				background-color: inherit;
			}

			b:nth-child(1) {
				position: absolute;
				top: 16px;
				left: -20px !important;
				height: 26px;
				width: 20px;
			}

			b:nth-child(1)::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-bottom-right-radius: 12px;
				//box-shadow: inset -4px -6px 3px -4px #c9c9c9;
			}

			b:nth-child(2) {
				position: absolute;
				top: 16px;
				right: -20px !important;
				height: 26px;
				width: 20px;
			}

			b:nth-child(2)::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-bottom-left-radius: 12px;
				//box-shadow: inset 2px -3px 3px -1px #c9c9c9;
			}
		}

/*  		li:first-child {
			margin-left: 0 !important;

			b:first-child {
				display: none;
			}
		}  */

		li.list {
			background-color: #ededed;
			color: black;
		}

		li.active {
			color: white;

			b::before {
				background-color: #ededed;
			}
		}
	}
}
