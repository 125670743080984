@import '../../Theme';

.new-message-modal {
  span {
    font-weight: bold;
    font-size: small;
  }

  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 0px;
  }

  .MuiTypography-body1 {
    font-size: small;
  }

  .MuiDialog-paperWidthSm {
    max-width: 750px;
  }

  .title--text {
    padding: 10px;
    border-bottom: 1px solid black;
    height: 50px;
    margin-top: -35px;
  }

  .heading--text {
    margin-left: 40px;
    width: 200px;
  }

  .icon--position {
    margin-top: -28px;
  }

  .text--field--inputs {
    display: flex;
    flex-direction: row;
  }

  .text--inputs {
    padding: 10px;
  }

  .text--size {
    width: 170px;
  }

  .phone--option {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .search--button {
    display: flex;
    height: 40px;
    margin-top: 28px;
  }

  .icon-button {
    display: flex;
    justify-content: flex-end;

    :hover {
      cursor: pointer;
    }
  }

}

@media only screen and (max-width: $breakpoint-xs) {
  .new-message-modal {
    animation-duration: .5s;
    animation-name: modalSlideIntoView;

    .title--text {
      height: 25px;
    }

    .text--field--inputs {
      display: flex;
      flex-direction: column;
    }

    .text--inputs {
      display: flex;
      flex-direction: column;
    }

    .text--size {
      width: 250px;
    }

    .MuiTypography-body1 {
      display: none;
    }

    .search--button {
      display: contents;

      .MuiButton-root {
        width: 250px;
        margin-left: 10px;
      }

    }

    .phone--option {
      display: flex;
      justify-content: center;
    }
  }
}





