.div-patient-details {
  padding: 35px 20px 10px 20px;

  .patient--details--bottom {
    padding-top: 12px;
  }

  .patient--details--bottom p:nth-child(2) {
    font-weight: 600;
  }
}