

#root, .App, .pane-right {
	width: 100%;
}

.online-booking {
	display: flex;
	flex: 1;   
	flex-direction: column;
	text-align: left;

	.tabs-cont {
		display: flex;
		flex: 1;   
		flex-direction: column;

		.filters-panels {
			padding: 20px;
			background-color: white;
			display: flex;
			flex-direction: column;
		}
	}
}

.online-booking-overflow {
	overflow: auto;
}

// .MuiPaper-elevation2,
// .MuiAccordion-root {
// 	box-shadow: 4px 4px 4px 4px #c9c9c9 !important;
// 	border-radius: 10px !important;
// }

.MuiPaper-elevation2 {
	padding: 20px;
}

.MuiTable-root {
	thead th {
		background-color: #eeeeee;
	}
}

.MuiAccordion-root {
	margin-bottom: 20px !important;

	.MuiAccordionSummary-root {
		background-color: #327dc6 !important;
		color: white !important;
	}
}

.MuiInputBase-formControl {
	border-radius: 10px !important;
	min-width: 150px;
}

// button:not(.MuiTableCell-root button, .MuiTabs-scroller
// 		button, .MuiInputBase-formControl button) {
// 	border-radius: 20px !important;
// 	min-width: 120px !important;
// }

.MuiTableCell-root > div[style] {
	justify-content: center;
}

.MuiTabs-scroller button:hover {
	background-color: #dfdfdf;
}

tfoot {
	.MuiToolbar-root,
	.MuiToolbar-root:hover {
		background-color: white !important;
	}
}

.help-icon-tooltip {
	display: none !important;
	position: absolute;
	bottom: 30px;
	z-index: 9999;
	border: 1px solid black;
	background-color: lightgoldenrodyellow;
	color: black;
	line-height: 22px;
	padding: 6px;
	display: block;
	width: 400px;
}

[tooltip] .help-icon-tooltip {
	display: block !important;
}

.help-icon {
	color: #327dc6;
	cursor: pointer;
}

.help-icon-cont {
	margin-bottom: 20px;
	color: #327dc6;

	p {
		position: relative;
		font-size: 10pt;

		svg {
			display: block;
			margin: 0 5px 0 0;
			float: left;
			font-size: 20pt;
		}
	}
}

label > span,
label > a {
	display: inline-block;
	vertical-align: middle;
}

.pi-tabpanel {
	> div {
		width: 100%;
		padding: 0;
	}
}

.MuiPaper-elevation2 {
	border-radius: unset !important;
	padding: 0;

	table {
		table-layout: auto !important;
		width: 100%;
	}

	.MuiTablePagination-root {
		overflow-x: hidden !important;
	}
}

.introjs-overlay {
	display: none;
	}