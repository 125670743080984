@import '../../Theme';

@mixin sheet($bottom) {
  position: absolute;
  bottom: $bottom;
  width: 180px;
  height: 120px;
  background-color: var(--app-color-light-bright);
  padding: 20px;
  border-radius: 10px;
  box-shadow: -9px 10px 12px rgba(27, 55, 100, .2);
  left: 50%;
  margin-left: -120px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 15px);
  grid-template-rows: repeat(auto-fill, 15px);
  bottom:70px;
  animation-duration: var(--app-animation-speed);
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  &::before {
    content: '';
    position: absolute;
    right: 84px;
    border-top: 15px solid var(--app-color-light-bright);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: -15px;
  }
}

.emoji {
  &--sheet {
    @include sheet(100px)
  }

  &--btn {
    cursor: pointer;
    font-size: 26px;
    user-select: none;

    &:hover {
      color: var(--app-color-primary);
    }
  }


  &--mark {
    cursor: pointer;
    font-size: 16px;
    min-width: 10px;
  }


}

@media only screen and (max-width: $breakpoint-sm) {
  .emoji {
    &--sheet {
      bottom: 50px;
      left: 0;
      margin-left: -98px;

      &::before {
        right: 90px;
      }
    }

    &--holder {
      padding: 0 5px;
    }

    &--btn {
      font-size: 24px;
    }
  }

}

