.reporting-header {
	h5 {
		display: inline;
		font-size: 23px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	h6 {
		display: inline;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.MuiDivider-vertical {
		border: solid 1px #979797;
		opacity: 0.8;
		display: inline;
	}

	.MuiSelect-root {
		padding: 8px;
		border-radius: 4px;
		border: solid 2px #979797;
	}
}

#div-reporting {
	.header-divider {
		margin: 0 84px 0 84px;
	}
}

.reporting-box {
	flex-grow: 1;
	max-width: 1700px;
	border-radius: 4px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

	> .reporting-data ~ .reporting-data {
		padding: 16px 16px 8px 16px;
	}
}

.reporting-search {
	padding: 16px;

	hr {
		margin-block-end: 0;
		margin-block-start: 0;
	}

	h5 {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	h6 {
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		color: #757575;
	}

	.search-select {
		max-width: 120px;
		border: solid 1px #979797;
	}

	.rectangle {
		width: 327px;
		height: 60px;
		margin: 0 0 0 235px;
		padding: 0 110px 0 31px;
		border: solid 1px red;
		background-color: #fff;
	}

	.collapse {
		padding-top: 24px;
	}

	.MuiSelect-select {
		padding-right: 24px;
		padding-left: 8px;
	}

	.MuiSelect-root {
		min-width: 150px;
	}

	.MuiDivider-vertical {
		border: solid 1px #979797;
		opacity: 0.35;
		display: inline;
	}

	.MuiButton-root {
		min-width: 120px;
		height: 50px;
		border-radius: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.MuiIconButton-root {
		padding-right: 4px;
	}

	.MuiButton-label {
		font-weight: normal;
	}

	.MuiTypography-body1 {
		display: inline;
	}

	.validatedDatePicker {
		display: inline-flex;
		div {
			display: inline-flex;
		}
	}

	.validatedPhoneNumber {
		display: inline-flex;
		vertical-align: top;
		div {
			display: inline-flex;
		}
	}

	.searchType {
		display: inline-flex;
		vertical-align: middle;
		text-decoration: none;
		div {
			display: inline-flex;
			text-decoration: none;
		}
	}

	.filterButton {
		display: inline;
		div {
			display: inline-flex;
			padding-top: 2px;
		}
	}

	@media (max-width: 1400px) {
		.search-select {
			max-width: 900px;
		}

		.MuiSelect-select {
			padding: 0 12px 0 4px;
		}

		.MuiDivider-middle {
			margin: 0 4px 0 4px;
		}

		.MuiIconButton-root {
			padding: 0;
		}

		.MuiInputBase-root {
			max-width: 220px;
		}

		.validatedDatePicker {
			div {
				max-width: 160px;
			}
		}

		.validatedPhoneNumber {
			div {
				max-width: 160px;
			}
		}

		.searchType {
			div {
				max-width: 200px;
			}
		}
		.filterButton {
			div {
				max-width: 160px;
				padding: 20px;
			}
		}
	}
}
