@import '../../Theme';

.ril-toolbar.ril__toolbar {
  z-index: 1000;
  top: 60px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.035);
}

button.ril-next-button.ril__navButtons.ril__navButtonNext, button.ril-prev-button.ril__navButtons.ril__navButtonPrev {
  background-color: rgba(0, 0, 0, 0.035);
}

.message--conversation--header {
  display: flex;
  justify-content: space-between;
}
.message--conversation--header, .message--conversation--header-new {
  border-bottom: 1px solid var(--app-color-light-shade);
  padding-left:20px;
  padding-right:20px;
  overflow: hidden;
  z-index: 9;

  .emptyName {
    height: 46px;
  }

  .row.hipaa-consent {
    display: none;
  }

  h1 {
    font-size: 120%;
    padding:0;
    margin-bottom:10px;
    width: fit-content;
  }
  p, span {
    font-size: 80%;
    padding:0;
    margin-top:0;
  }

  .btn-back {
    display: none;
  }
  .message--location-badge {
    display: block;
    padding: 2px 10px;
    background-color: var(--app-color-medium-tint);
    border-radius: 30px;
    position: relative;
    box-shadow: 1px 6px 14px rgba(0,0,0,.07);
    word-break: break-word;
    color: $white;
    margin-bottom:10px;
    font-size: revert;
    padding: 2px 10px;
    width: fit-content;
    p {
      margin: 0;
      white-space: nowrap;
      word-break: keep-all;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .col {
    align-self: flex-end;
  }
  .col:first-child{
    flex-basis: 50%;
  }

}

.message--conversation--refresher {
  position: absolute;
  height: 12px;
  text-align: right;
  font-size: 80%;
  display: inline-block;
  width: 100%;
  top: -30px;
  z-index: 24;
  left: 0;

  p {
    padding-right: 140px;
    opacity: .3;
    display: inline-block;
    position: relative;
    top:3px;
  }
  .btn-refresh {
    padding-left: 0 !important;
    margin-left: 0 !important;
    width: 25px;
    min-width: 10px !important;
    padding-right: 10px !important;
    position: relative;
    top: -1px;
    &:hover {
      background-color: transparent !important;
    }
  }
  &--spinner {
    display: inline-flex;
    transform: scale(0.5);
    top: -1px;
    position: relative;
    vertical-align: middle;
    margin: 5px 0;
    width:25px;
    left:-2px;
  }
}
.message--optout {
  padding:20px;
  border-top: 1px solid var(--app-color-light-shade);
}
.message--conversation--holder {
  z-index: 7;
  text-align: left;
  animation-duration: var(--app-animation-speed);
  animation-name: slideIn;
  animation-fill-mode: forwards;
  margin-left:$messageListWidth;
  background-color: var(--app-color-light);
  overflow: visible;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  top: $toolbarheight - 21px;
  left: $messageListWidth ;
  width: calc(100% - #{$messageListWidth} );
  overflow: visible;


  svg.svg-inline--fa.fa-exclamation-triangle.fa-w-18 {
    right: 10px;
    position: relative;
    align-self: center;
    width: 1em;
    height: 0.75em;
  }
  
  .message--label-status {
    right: 10px;
    color: var(--app-color-red);
    cursor: pointer;
  }  
}
.message--conversation {
  div.message--label {
    all: unset;
    display: flex;

  }
}

.message--conversation--list {
  overflow-y: scroll;
  flex: 1 1 auto;
  height:70vh;
  min-height: 70vh;

  div {
    margin:0px 60px;
    margin-bottom:10px;

    span {
      font-size: 70%;
      display: block;
      opacity:1;
      position: relative;
      padding:2px
    }

    .img-holder, img {
      all: unset;
      cursor: pointer;
      display: flex;
      min-width:250px;
      width: -webkit-fill-available;
      max-width:400px;
      margin: auto auto;
    }

    img.image--img {
      font-style: italic;
    }

    p {
      display: block;
      padding:10px;
      background-color: var(--app-color-light-bright);
      min-width:250px;
      width: calc(100% - #{$messageListWidth} );
      border-radius: 10px;
      margin:1px 0;
      position: relative;
      box-shadow: 1px 6px 14px rgba(0,0,0,.07);
      line-height: 1.5rem;
      word-break: break-word;
      max-width:400px;

      &:before {
        content: "";
        position: absolute;
        left: -5px;
        width: 0;
        bottom: -5px;
        border-top: 20px solid var(--app-color-light-bright);
        border-left: 0px solid transparent;
        border-right: 15px solid transparent;
        transform: rotate(45deg);
      }
    }

    &.sent {

      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        right:10px;
      }
      p {
        background-color: var(--app-color-primary-tint);
        color: var(--app-color-dark);

        &:before {
          left:unset;
          right: -5px;
          border-top: 20px solid var(--app-color-primary-tint);
          border-left: 15px solid transparent;
          border-right: 0px solid transparent;
          transform: rotate(-45deg);
          bottom: -5px;
        }
      }
      &.image--holder {
        all: unset;
        min-width:250px;
        width: calc(100% - #{$messageListWidth} );
        max-width:400px;
      }
      span.image--card {
        right: 2px;
        div {
          margin: 0;
        }
      }
    }
    &.receive {
      span {
        left:10px;
      }
      span.image--card {
        left: -2px;
        div {
          margin: 0;
        }
      }
    }
  }

}

@media only screen and (max-width: $breakpoint-xs) {
  .message--conversation--header {
    .row {
      width: fit-content;
    }
    h1 {
      width: auto;
    }
    .row.hipaa-consent {
      padding-left: 20px;
      display: flex;
      width: auto;
    }
    .emptyName {
      height: 15px;
    }
    flex-direction: column;
    .btn-back {
      display: inline-block;
      padding: 40px;
      padding-left: 0px;
      padding-right: 100px;
      font-size: 30px;
      left: 5px;
      transform: rotate(0deg);
    }
    .col {
      flex-basis: 50%;
      align-self: flex-start;
    }
    .message--location-badge {
      margin-bottom: .67em;
    }
  }
  .message--conversation--list {
    height: 510px;
    min-height: 510px;
    div {
      margin: 0px 25px;
    }
  }
  .message--conversation--holder{
    z-index: 10;
    left: 100vw;
    display: none;
    position:fixed;
  }
  .message--conversation--holder--input--active {
    display: block;
    position: fixed;
    left: initial;
    right: 0;
    bottom: -100px;
    z-index: 10;
    width: 100%;
    height: 265px;
    top: initial;
    background:transparent;
    overflow:visible;
  }
  .message--conversation--header {
    padding-bottom:10px;
    padding-left:40px;
  }
  .message--conversation--holder.message--conversation--active {
    display: block;
    overflow: hidden;
    padding: 0;
    padding-bottom: 200px;
    top: $toolbarheight;
    left:0;
    width: 100vw;
    animation-duration: var(--app-animation-speed);
    animation-name: slideIntoView;
    animation-fill-mode: forwards;
  }

  .message--conversation--refresher {
    top: 120px;
    z-index: 10;
    p {
      padding-right: 20px;
    }
  }
  .message--conversation--holder
  .conversation-input-holder .conversation-input-error-holder {
    margin-left: 0px;
    padding: 0px 20px 20px;
  }
}
