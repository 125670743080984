@import "../../Theme";

.two-way-cont-card .card {
	font-size: 95%;
	display: block;
	padding: 15px 15px 15px 30px;
	border-bottom: 1px solid var(--app-color-dark-tint);
	border-bottom: 1px solid var(--app-color-light-shade);
	transition: all 0.24s;
	position: relative;
	line-height: 15px;
	word-break: break-word;
	height: 50px;

	span.status {
		position: absolute;
		top: 40%;
		left: 10px;
		width: 6px;
		height: 6px;

		svg {
			color: var(--app-color-red);
		}
	}

	.dot {
		position: absolute;
		top: 40%;
		left: 10px;
		width: 8px;
		height: 8px;
	}

	.row {
		span:first-child {
			font-weight: bold;
		}

		span:nth-child(2) {
			opacity: 1;
			font-size: 70%;
		}

		.location--label {
			opacity: 1;
			font-size: 70%;
			text-transform: uppercase;
		}
	}

	p {
		margin: 5px 0;
	}
}

.row.hipaa-consent {
	justify-content: flex-start;
	align-items: center;
	span,
	svg {
		font-size: 12px;
		color: var(--app-color-warning);
	}
	svg {
		padding-right: 5px;
	}
}

.card--holder {
	position: relative;
	cursor: pointer;

	.card--content {
		transition: left 0.24s;
		left: 0;
		position: relative;
	}

	&:hover,
	&.active {
		.card--options {
			pointer-events: revert;

			.card--options--flag--holder {
				right: -5px;
				transition-delay: 0.04s;
			}

			.card--options--trash {
				right: -5px;
			}
		}

		.card--content {
			left: -10px;
		}

		.card {
			cursor: pointer;
			background-color: var(--app-color-primary-light);
			color: var(--app-color-dark);
		}
	}

	.card {
		font-size: 95%;
		display: block;
		padding: 15px 15px 15px 30px;
		border-bottom: 1px solid #d7d8da;
		border-bottom: 1pxsolidvar(--app-color-light-shade);
		transition: all .24s;
		position: relative;
		line-height: 15px;
		word-break: break-word;
		height: 50px;
	}	

	.card.active {
		cursor: pointer;
		background-color: var(--app-color-primary-light);
		color: var(--app-color-dark);
	}
}

.card--options {
	position: absolute;
	z-index: 1;
	top: 0;
	display: flex;
	height: 80px;
	pointer-events: none;
	right: 0;

	.card--option {
		padding: 25px;
		padding-top: 30px;
		background-color: var(--app-color-primary);
		color: var(--app-color-light-bright);
		cursor: pointer;
		position: relative;
	}

	&--more {
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0.3;
		cursor: pointer;
		padding: 32px 11px 0px 6px;
	}
	&--flag--holder {
		border-right: 2px solid rgba(255, 255, 255, 0.1);
		right: -$messageListWidth - 50px;
		transition: right 0.24s;

		&:hover .card--options--flags {
			top: 80px;
		}
	}

	.card--options--flag {
		position: relative;
		z-index: 1;
	}

	.card--options--flags {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		top: 80px;
		position: absolute;
		left: 0;
		line-height: 0;
		transition: all 0.24s;
		top: 0px;
		z-index: -1;

		.dot {
			top: 0;
			left: 0;
			width: 10px;
			height: 10px;
		}

		div {
			padding: 10px;
			border: 1px solid var(--app-color-light);
			background-color: var(--app-color-light-bright);
			box-shadow: 1px 0px 19px rgba(27, 55, 100, 0.07);

			&:nth-child(1) {
				grid-column-start: 1;
				grid-row-start: 1;
				grid-row-end: 1;
			}

			&:nth-child(2) {
				grid-column-start: 2;
				grid-row-start: 2;
				grid-row-end: 1;
			}

			&:nth-child(3) {
				grid-column-start: 1;
				grid-row-start: 2;
				grid-row-end: 2;
			}

			&:nth-child(3) {
				grid-column-start: 2;
				grid-row-start: 2;
				grid-row-end: 2;
			}

			&:hover {
				background-color: var(--app-color-light);
			}
		}

		.dot {
		}
	}

	.card--options--trash {
		transition: right 0.24s;
		right: -$messageListWidth - 50px;
		background-color: var(--app-color-red);

		&:hover {
			background-color: var(--app-color-danger-tint);
		}
	}
}

@media only screen and (max-width: $breakpoint-xs) {
	.card--options {
		right: 0vw;
	}
} ;
