.div-patient-attendance {

  p {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  p:nth-child(1) {
    padding: 5px;
    font-size: 14px;
    font-weight: normal;
  }

  p:nth-child(3), p:nth-child(4), p:nth-child(6), p:nth-child(7) {
    padding-left: 15px;
  }

  p:nth-child(3), p:nth-child(6) {
    padding-top: 10px
  }

  p:nth-child(4) {
    padding-bottom: 10px
  }


  .attendance-label {
    font-size: 10px;
    font-weight: 600;
    line-height: 2.2;
  }

  .attendance-description {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }

  .attendance-missed-total p {
    padding: 0;
    color: #de0000;
    font-size: 12px;
    line-height: 1.83;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    width: 95%;
  }

  .attendance-missed-total {
    margin: 15px 0 0 10px;
    padding-left: 8px;
  }


}

