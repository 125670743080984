@import '../../Theme';

.version {
  position: fixed;
  left: 20px;
  bottom: 0px;
  z-index: 9999;
  color: $gray-md-dark;
}

.center {
  width: 700px; 
  margin: auto; 
  text-align: center;
  
  /* Optional Styling */
  padding: 20px;
  border-style: solid;
  border-color: #B6C7D6;
  border-radius: 20px;
  background-color: #EAF0F6; 
}

.App {
  /*
  text-align: center;
   */

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .App-header {
    background-color: $primary;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: $primaryHighlight;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}