$breakpoint-xs: 750px;
$breakpoint-sm: 1024px;

.select-cover-message {
	text-align: center;
}

.div-schedule {
	.rbc-events-container {
		margin-right: 0 !important;

		.rbc-event {
			opacity: 0.8;
			border-radius: 0;
			border: none !important;
		}
	}

	.rbc-month-row {
		flex-basis: 150px;
	}

	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

	h6 {
		margin-right: 50px;
		display: inline;
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	p {
		display: inline;
		margin: 0 1rem 0 1rem;
	}

	// .MuiButton-root {
	// 	background-color: #fff;
	// 	border: 2px solid rgba(0, 0, 0, 0.12);
	// 	box-shadow: none;
	// 	margin-right: 200px;
	// }

	.MuiGrid-root {
		padding: 8px;
	}

	.MuiSvgIcon-root {
		font-size: 20px;
	}

	.MuiGrid-item {
		padding: 0 !important;
	}

	.rbc-event.available-style {
		background-color: #3f51b5 !important;
		border: 1px solid black !important;
	}

	.rbc-event.break-style {
		background-color: gray !important;
		border: 1px solid black !important;
	}

	.pageLabel {
		transform: translate(0, 1.5px) scale(0.75);
		margin-bottom: 0;
		font-size: 0.5rem !important;
	}
}

.validator-message {
	color: red;
	font-size: 12px;
	line-height: 1.66;
	letter-spacing: 0.5328;
	padding-left: 12px;
	margin: 0;
	opacity: 0.9;
}

.repetition {
	background-color: #f8f8f8;
	padding: 1px 4px;

	legend {
		font-size: 13px;
	}

	.MuiGrid-item {
		padding: 4px;
	}

	.MuiFormControl-root {
		width: 100%;
	}

	.MuiFormGroup-root {
		display: block;

		.MuiFormControlLabel-root {
			display: inline-block;
			margin-left: 0;
			margin-right: 2px;

			.MuiButtonBase-root {
				padding: 2px;
			}

			.MuiSvgIcon-root {
				width: 16px;
			}

			.MuiTypography-root {
				font-size: 12px;
			}
		}
	}
}

.calendar-event-selected {
	opacity: 0.5;
}

.fc-state-highlight {
	background-color: hsl(34, 94%, 88%) !important;
}

.fc-event {
	font-size: 11px;

	.fc-event-time {
		white-space: nowrap;
	}

	.event-actions {
		position: absolute;
		top: 0px;
		right: 4px;
		z-index: 99999;
		cursor: pointer;
		color: white;
	}

	img {
		width: 20px;
		height: 20px;
	}
}

.group-info {
	width: 8px;
	height: 8px;
	border: none;
	border-radius: 4px;
	margin-right: 2px;
	display: inline-block;
}

.update-single-event-notification {
	padding: 5px;
	text-align: center;
	background-color: orange;
	color: white;

	* {
		vertical-align: middle;
	}
}

.validatedDatePicker {
	display: inline;
	div {
		display: inline-flex;
	}
}

@media (max-width: 1400px) {
	.validatedDatePicker {
		div {
			max-width: 160px;
		}
	}
}


@media only screen and (max-width: $breakpoint-xs) {  
	.fc-header-toolbar {
		flex-direction: column;
		align-items: flex-start;
	}
}

.form-drawer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	form {
		flex-grow: 1;
	}

	.data-diagnostics {
		margin-top: 20px;
		font-size: 9px;
	}
}