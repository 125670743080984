.field--label {
	transform: translate(14px, -6px) scale(0.75) !important;
}

.insurance-data {
	.Mui-disabled {
		visibility: hidden;
	}
}

#daysBlocked-label {
	pointer-events: initial;
}

.page-header {
	h5 {
		display: inline;
		font-size: 23px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}
}

.page-body-outline {
	h6 {
		display: inline;
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.MuiInputAdornment-root {
		color: #000000;
		opacity: 0.5;
		padding: 5px;
	}

	.MuiOutlinedInput-adornedStart {
		padding: 0 12px 0 12px;
	}

	.MuiButton-root {
		height: 55px;
		width: 120px;
		border-radius: 4px;
	}

	.MuiTypography-body1 {
		font-size: 18px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.MuiTableCell-root {
		padding: 4px;
	}

	.MuiTable-root tr td:first-child div {
		justify-content: center;
	}

	.MuiTableCell-head {
		line-height: 1.25rem;
	}

	thead tr th:first-child[style] {
		width: 100px !important;
	}

	input::placeholder {
		opacity: 1;
		font-weight: normal;
	}
}
