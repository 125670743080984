.pagination-container {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px; // Optional: Add margin for spacing

  .pagination,
  .rows-per-page {
    display: flex;
    align-items: center;
  }

  .rows-per-page {
    margin-left: 20px; // Add margin to create space between pagination and dropdown
    label {
      margin-right: 10px; // Add margin for spacing between label and dropdown
    }
  }
}
