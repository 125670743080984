@import '../../Theme';

.image-card--holder {
  position: relative;
  justify-self: center;
  cursor: default;
  margin-right: 10px;
}

.card--image {
  border-radius: 10px;
  height: 40px;
  width: 40px;
}

.image-card--btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  cursor: pointer;

  background: white;
  border-radius: 50px;
  padding: 3px 2px 1px;
  font-size: 10px;
  color: black;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, .3);
}