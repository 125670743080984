@import '../../Theme';

@mixin templatesheet($bottom, $width) {
  position: absolute;
  bottom: $bottom;
  width: $width;
  height: 220px;
  background-color: var(--app-color-light-bright);
  padding: 20px;
  border-radius: 10px;
  box-shadow: -9px 10px 12px rgba(27, 55, 100, .2);
  left: 50%;
  transform: translateX(-50%);
  margin-left: -120px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 15px);
  grid-template-rows: repeat(auto-fill, 15px);
  animation-duration: var(--app-animation-speed);
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  color:var(--app-color-dark);

  &::before {
    content: '';
    position: absolute;
    right: 84px;
    border-top: 15px solid var(--app-color-light-bright);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: -15px;
  }
}

.template {
  &--sheet {
    @include templatesheet(70px, 400px);

    &--scrollable {
      height: 220px;
      overflow-y: scroll;
    }
  }

  &--btn {
    cursor: pointer;
    font-size: 26px;
    user-select: none;

    &:hover {
      color: var(--app-color-primary);
    }
  }

  &--item {
    display:flex;
    align-content: space-around;
    padding:10px;
    justify-content: space-between;

    &:hover {
      background:var(--app-color-primary-light);
      cursor: pointer;
      border-radius: 50px;
    }

    div + div:last-child {
      color:var(--app-color-medium-shade);
      margin-left:20px;
      align-content: end;
    }

    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0;
    }

    &-name {
      font-weight: 600;

      span {
        font-size: var(--font-size-small);
        opacity: .6;
        background-color: var(--app-color-light-shade);
        padding:5px 10px;
        margin:0 10px;
        border-radius: 50px;
      }
    }

    &-date {
      font-size: 14px;
    }

  }
  &--mark {
    cursor: pointer;
    font-size: 16px;
    min-width: 10px;
  }


}

@media only screen and (max-width: $breakpoint-sm) {
  .template {
    &--sheet {
      @include templatesheet(140px, 300px);
      bottom: 50px;
      left: 140px;
      margin-left: -98px;
      width: 300px;
      &::before {
        right: 180px;
      }
    }

    &--holder {
      padding: 0 5px;
    }

    &--btn {
      font-size: 24px;
    }
  }

}

