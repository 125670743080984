@import '../../Theme';

.primary-location {
  text-overflow: ellipsis;
  word-break: normal;
  white-space: nowrap;
  display: inline-block;
  width: 85%;
  overflow: hidden;
}

.nav-toggle {
  display: none;
  color: var(--app-color-dark-contrast);
  position: relative;
  top: 12px;
  right: 30px;
  text-align: left;
  font-size: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 9999;
}

nav {
  margin-top: $toolbarheight;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  text-align: left;
  min-width: var(--side-min-width);
  max-width: var(--side-max-width);
  height: 100vh;
  position: fixed;
  background-color: var(--app-color-light);
  z-index: 20;
  top: 0;
  left: 0;

  box-shadow: 1px 0px 19px rgba(27, 55, 100, .07);
  border-right: 1px solid rgba(27, 55, 100, .2);

  .mobile--user {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        color: var(--app-text-color);
      }  
    }
  }



  span.circle-bg {
    background-color: var(--app-color-medium-tint);
    color: var(--app-color-light);
    display: inline-block;
    padding: 6px 10px;
    border-radius: 20px;
    margin-right: 10px;
    opacity: .8;
    font-size: 90%;
  }

  h1 {
    font-size: 16px;
    margin: 0;
  }

  a, h1, p {
    display: block;
    color: var(--app-text-color);
    transition: all .04s linear;
    text-decoration: none;
    position: relative;
    padding: 20px;
    cursor: default;

    img {
      filter: var(--svg-invert);
    }

    svg {
      position: relative;
      top: 3px;
    }

    /*span {
      pointer-events: none;
      position: absolute;
      background-color: var(--app-color-dark);
      padding:10px 12px;
      border-radius: 20px;
      color: var(--app-color-light);
      font-size: .7rem;
      box-shadow: 0 5px 10px rgba(0,0,0,.2);
      left: 63px;
      width: 70px;
      top: 10px;
      text-align: center;

      &:before{
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 8px solid var(--app-color-dark);
        left: -4px;
        top: 6.5px;
      }

    }*/

    &:hover {
      background-color: var(--app-color-light);

    }


  }
}

.cursorPointer
{
  cursor:pointer;
}

@media only screen and (max-width: $breakpoint-sm) {
  body {
    margin-left: 0;
  }
  @media only screen and (max-width: $breakpoint-xs) {
    .nav-toggle {
      display: inline-block;
    }
  }
  nav {
    transform: translateX((-300px));
    transition: transform 240ms ease-in-out;

    .mobile--user {
      align-items: center;
      justify-content: space-between;
      display: flex;

      svg.MuiSvgIcon-root {
        padding: 20px 20px 20px 0px;
      }
    }
  }
  nav.open {
    transform: translateX(0);
    min-width: var(--side-max-width);
    max-width: var(--side-max-width);

    ul {
      margin: 0;
    }

    /*a {
      span {
        text-indent: 0px;
        position: relative;
        background-color: transparent;
        padding: 0 20px;
        border-radius: 20px;
        color: var(--app-color-dark);
        font-size: 1rem;
        box-shadow: none;
        left: 0px;
        width: auto;
        top: -5px;
        text-align: left;

        &:before{
          content: '';
          display: none;

        }

      }

      &:hover {
        background-color: none;
        transform: none;

        span {
          left: 0px;
        }
      }
    }*/
  }
}