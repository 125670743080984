.div-customer-information {
  .row--divider {
    height: 2px;
    list-style-type: none;
    background-color: #979797;
  }

  .divider--blue {
    width: 10px;
    opacity: 0.52;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, #d9f3ff, #d9f3ff);
  }

  .MuiGrid-root {
    height: 190px
  }

}