.div-schedule {
	flex: 1;
    display: flex;
    flex-direction: column;
}

.diaglogtitle > * {
	color: #040505 !important;
	font-size: 25px !important;
	background-image: linear-gradient(to right, #e6e6e9, white);
	width: 100%;
	padding: 10px;
}

table {
	table-layout: fixed;
	width: 100%;
}

.schedule-preview .btn-left {
	cursor: pointer;
	position: absolute;
	left: 30px;
	top: 190px;
}
.schedule-preview .btn-right {
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 190px;
}
.schedule-preview .btn-left:hover,
.btn-right:hover {
	transform: scale(1.3);
}
.schedule-preview table th {
	text-align: center;
	border: none;
	color: #818585;
	//max-width: 40px;
	font-weight: 500;
	font-size: 15px;
}
.schedule-preview table td {
	text-align: center;
	border: none;
	max-width: 40px;
}
.schedule-preview .block {
	background-color: #169be8;
	border-radius: 5px;
	border-color: blue;
	border-width: 12px;
	color: #fff;
	font-size: 14px;
	height: 30px;
	width: 70px;
	padding-top: 4px;
	font-weight: 500;
	margin: auto !important;
}
.schedule-preview .empty::before {
	content: "";
	text-align: center;
	height: 3px;
	width: 35px;
	margin: 5px auto 0;
	background-color: #ddd;
	display: block;
}

.schedule-preview tbody {
	display: block;
	height: 320px;
	overflow: auto;
}


.schedule-preview thead {
	display: table;
	width: 96%;
	table-layout: fixed;
	align-items: center;
}

.schedule-preview thead tr th {
	align-items: center;
	width: 40px;
}
.schedule-preview tbody tr {
	display: table;
	width: 98%;
	table-layout: fixed;
}

.schedule-preview tbody  {
	display:block;
	//padding-top: 10px;
    max-height: calc(100vh - 600px);
    overflow-y: scroll;	
}
