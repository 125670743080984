@import '../../Theme';

.locationDropDownAndMessageList{
  display: block;
  background-color: var(--app-color-light-bright);
  box-shadow: 1px 0px 19px rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  .locationDropDown {
    display: column;
    flex: 1;
    flex-direction: column;
    position: relative;
    transform: translateX(0px);
    //min-height: 100px;
    // width: unset;
    top: unset;
    min-width: 290px;

    .sidebarTitle {
      width: unset;
      height: unset;
      position: unset;
      left: unset;
      margin-top: 10px;    
      margin-left: 10px;   
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    #nav {
      //margin-top: 60px;

      ul {
        left: unset;
        position: unset;
        padding-top: unset;
        margin-left: -15px;
      }
    }

    #nav.location-aware {
      margin-top: 0;
    }

    #circle {
        display: flex;
        width: 40px;
        height: 40px;
        background: rgb(197, 197, 197);
        border-radius: 50px;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        margin-right: 10px;
        margin-left: 3px;
        color: #1074C1;  
        /* place items in column */
        flex-direction: column;
        /* center flex items horizontally */
        align-items: center;
        /* center all content vertically */
        justify-content: center;    
        text-align: center;
    }
  }

  .multipleLocation
  {
    position: fixed;
    z-index: 9999;
    top : 140px;
    
    #sidebarMenuLocation {
      .modal {
        display: block;
        height: unset;
        left: 560px;
        top: 368px;
        background-color: unset;
        box-shadow: none;
        position: fixed;
        width: 540px;

        .modal-cont {
          background-color: var(--app-color-light-bright);
          padding: 20px;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          
          .btn-close {
            top: 10px;
            right: 10px;
          }

          .optionContainer {
            li:not(:first-child) {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

.message--list--header {
  padding: 15px;
  border-bottom: 1px solid var(--app-color-light-shade);
}


.message--list {
  max-width: $messageListWidth;
  width: $messageListWidth;
  text-align: left;
  top: $toolbarheight;
  padding: 0;
  background-color: var(--app-color-light-bright);
  height: calc(100vh - #{$toolbarheight});
  position: fixed;
  box-shadow: 1px 0px 19px rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  // animation-duration: var(--app-animation-speed);
  // animation-name: slideIn;
  // animation-fill-mode: forwards;
  z-index: 10;

  .message--list--scroll {
    overflow: hidden;
    height: calc(100vh - (#{$toolbarheight} - 100px));
    overflow-y: scroll;

    &--loadmore--holder {
      height: 70px;
      padding-bottom: 50px;
      padding-top: 20px;
      text-align: center;

      button {
        margin: 0 auto;
      }
    }
  }

  .message--list--input--search {
    padding: 20px;
    position: relative;
    border-bottom: 1px solid var(--app-color-light-shade);

    .btn-search {
      position: absolute;
      top: 25px;
      left: 25px;
      display: inline-block;

      &--close {
        right: 25px;
        top: 25px;
        cursor: pointer;
        position: absolute;

        &:hover {
          color: var(--app-color-danger);
        }
      }
    }

    input {
      margin: 0px;
      padding: 5px 10px;
      padding-left: 26px;
      border-radius: 20px;
      border: 1px solid var(--app-color-light-shade);
    }

    .txt--alert {
      margin-left: 25px;
    }
  }

  .message--list--refresher {
    font-size: 80%;
    padding: 0 30px 0;
    border-bottom: 1px solid var(--app-color-light-shade);
    height: 40px;

    &--buttons {
      width: 30px;
      display: inline-block;;
    }

    p {
      margin: 0;
      display: inline-block;
      padding: 0;
      line-height: 40px;
      padding-right: 10px;
    }

    .btn-refresh {
      padding-left: 0 !important;
      margin-left: 0 !important;
      width: 25px;
      min-width: 10px !important;
      padding-right: 10px !important;
      position: relative;
      /*top: -10px;
      right:20px;*/
    }

    &--spinner {
      display: inline-flex;
      transform: scale(0.5);
      position: relative;
      vertical-align: middle;
      margin: 5px 0;
      width: 25px;
      /*top: -15px;
      right:20px;*/
    }
  }

  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 20px;
    position: relative;
    top: -2px;
    left: -3px;

    &--blue {
      background-color: $primaryHighlight;
    }

    &--green {
      background-color: $green;
    }

    &--purple {
      background-color: $purple;
    }

    &--clear {
      background-color: $white;
      border: 1px solid var(--app-color-light-shade);
    }
  }

  .btn-row {
    margin-bottom: 20px;

    button {
      box-shadow: none;
    }

    button:first-child {
      margin-right: 10px;
    }
  }

  ul {
    font-size: 80%;

    li {
      cursor: pointer;
      border-bottom: 2px solid transparent;

      &:hover, &.active {
        border-bottom: 2px solid $orange;

      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .message--list {
    max-width: 100vw;
    width: 100vw;
  }
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s
  }

  /* Modal Content */
  .modal-content {
    position: fixed;
    bottom: 0;
    background-color: #fefefe;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
  }

  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  .modal-body {
    padding: 2px 16px;
  }

  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  /* Add Animation */
  @-webkit-keyframes slideIn {
    from {
      bottom: -300px;
      opacity: 0
    }
    to {
      bottom: 0;
      opacity: 1
    }
  }

  @keyframes slideIn {
    from {
      bottom: -300px;
      opacity: 0
    }
    to {
      bottom: 0;
      opacity: 1
    }
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }
}

/**
Bug 12220
10623 Hipaa disclaimer
 */
