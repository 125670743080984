$breakpoint-xs: 750px;
$breakpoint-sm: 1024px;

.filters {
	text-align: left;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 20px;
	
	> * {
		white-space: nowrap;
		max-width: 20%;
		min-width: 175px;		
	}

	.MuiInputBase-root{
		padding: 0 !important;
	}

	input {
		padding: 14px !important;
	}

	#Cover,
	#Location,
	#Provider {
		.material-icons {
			vertical-align: middle;
			margin-right: 5px;
		}
	}

	input[type="text"]:disabled {
		background-color: initial;
		color: #e0e0e0;
	}

	.filters-checkbox {
		margin: 0;
		min-width: none;

		.MuiCheckbox-root {
			padding: 0.25rem;
	
			&:hover {
				background-color: transparent;
			}
		}
	
		.MuiSvgIcon-root {
			font-size: 1.5rem
		}
	
		.MuiFormControlLabel-label {
			font-size: 0.9rem;
			text-wrap: pretty;
			user-select: none;
		}
	}	
}


@media only screen and (max-width: $breakpoint-xs) {
	.filters {
		flex-direction: column;
		align-items: flex-start;
		gap: 1.25rem;

		> * {
			flex: 1;
			max-width: unset;
		}
	}
}