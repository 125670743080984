@import '../../Theme';

.navigation .sidebarTitle 
{
    width: 100%;
    height: 25px;
    position : fixed;
    left :25px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #FFFFFF;
}

.navigation
{
    display: inline-block;
    transform: translateX((-290px));
    transition: transform 240ms ease-in-out;    
    position : fixed;
    min-height: 100vh;
    width : 290px;
    //background: #03122C;
    background: rgb(3,18,44);
    background: linear-gradient(180deg, rgba(3,18,44,1) 78%, rgba(20,59,122,1) 100%);
    top : 63px;
    margin :0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100px;
}
.navigation ul {
    top: 30;
    left: -15px;
    position: absolute;
    padding-top: 20px;
    width: 100%;
}

.navigation ul li{
    position: relative;
    list-style: none;
    width : 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}


.navigation ul li span{
    position: relative;
    display: block;
    width : 100%;
    display: flex;
    text-decoration: none;
    //height: 60px;
    line-height: 60px;    
    //color: white;
    padding-left: 15px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;     
}


.navigation ul .active{
    position: relative;
   cursor: pointer;
   background-color: white;
   width : calc(100% - 23px);
   height: 45px;
  // margin-top: 10px;
   color : #235789;
   border-top-left-radius: 20px;
   border-bottom-left-radius: 20px;
   font-family: "Open Sans", sans-serif;
   font-style: normal;
   font-weight: 600;
   font-size: 6px;
   line-height: 8px;
   display: flex;
   align-items: center;   
   text-align: center;  
}

.navigation ul .list{
    position: relative;
    cursor: pointer;
    color: white;
   //background-color: green;
    height: 45px;
    //margin-top: 20px;
    //margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 6px;
    line-height: 8px;
    display: flex;
    align-items: center; 
    text-align: center;   
 }

 .activeLink{ 
    text-decoration: none;
    height: 35px;
    color: #235789;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 6px;
    line-height: 22px;    
 }
 
 .inActiveLink{ 
    text-decoration: none;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 6px;
    line-height: 22px;    
 }


  .navigation ul li.active b:nth-child(1) {
    position: absolute;
    top: -20px;
    left : 2px;
    height: 20px;
    width: calc(100% - 1px);
    background: white;
    border : 0px;
    //border-bottom-left-radius: 20px;
    //display: none;
  }

  .navigation li.active b:nth-child(1)::before {
    content: '';
    position: absolute;
    //top: 0;
    left: -3px;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    background: #03122C;
  }

  .navigation li.active b:nth-child(2) {
    position: absolute;
    bottom: -20px;
    left : 2px;
    height: 20px;
    width: calc(100% - 1px);
    color : #03122C;
    background: white;
    //border-bottom-left-radius: 20px;
    //display: none;
  }

  .navigation li.active b:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 0;
    left: -3px;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    background: #03122C;
  }
 
.navigation ul .active .itemActive
{
    width : 25px;
}

.navigation ul .list .itemInActive
{
    display:  none;
    width : 95px;
}

.navigation ul .title
{
    width : 195px;
}

.navigation .chatWidget 
{
  
    position: absolute;
    z-index: -1;
    left : 0 !important;
    top : 0 !important;
    background-color: red;
}

.multipleLocation
{
  position: fixed;
  z-index: 9999;
  top : 140px;
}

.mobile--user {
  position : relative;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  display: flex;
  color : white;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 22px;    

  svg.MuiSvgIcon-root {
    padding: 20px 20px 20px 0px;
  }
}

.drawer-contents {
  width: 300px;
  height: 100%;
  background-color: #03122c;
  padding: 20px 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li:first-child {
      height: 60px;

      .sidebarTitle {
        color: white;
        display: flex;
        align-items: center;
      }
    }

    li {
      padding: 10px 20px;
      box-shadow: inset 2px -2px 5px -1px grey;

      a {
        color: white;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
}

.drawer-contents-new {
  width: 300px;
  background-color: #03122c;
  padding: 20px 0;
  height: 100%;
  overflow-y: auto;

  .mainItem
  {
    font-weight: bold;
  }

  .subItem
  {
    padding-left: 30px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 7.5px 20px;
      box-shadow: inset 2px -2px 5px -1px  #03122c; 

      a {
        color: white;
        font-size: 14px;
        text-decoration: none;
      }
    }

    li.logout {
      bottom: 1rem;
      position: fixed;
    }
  }
}