.div--appointment--details {
  flex-grow: 1;
  max-width: 14.666667%;
  flex-basis: 14.666667%;

  .div-time {
    color: var(--app-color-light);
    background-color: #606060;
  }

  .div-time h6 {
    padding-right: 10px;
    font-size: 22px;
  }

  .div-details {
    padding-bottom: 16px;
  }

  .div-details p {
    padding-right: 5px;
    font-size: 10px;
    line-height: 2.2;
  }

  .div-details p:nth-child(2) {
    margin: 10px 0 20px 0;
  }

  .MuiSvgIcon-root {
    font-size: 14px;
    float: left;
    padding: 8px 0 0 8px;
  }

  .div-appointment-no-show {
    background-color: rgba(223, 176, 50, 0.1);
  }

  .div-appointment-cancelled {
    background-color: rgba(255, 0, 0, 0.1);
  }

  .appointment-details-noshow-icon {
    fill: #dfb032;
  }

  .appointment-details-cancelled-icon {
    fill: #ff0000;
  }

  svg {
    stroke: var(--app-color-light);
  }

}