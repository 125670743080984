.div-patient-history {

  p {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  p:nth-child(1) {
    padding: 5px;
    font-size: 14px;
    font-weight: normal;
  }

  p:nth-child(n+2) {
    padding-left: 15px;

  }

  .history-label {
    font-size: 10px;
    font-weight: 600;
    line-height: 2.2;
  }

  .history-description {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }

  a {
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1074c1;
    display: flex;
    align-items: flex-start;
  }

  a:hover {
    cursor: pointer;
  }
}