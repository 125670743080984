@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';


.MuiTablePagination-root {
  background-color: white;
  background: white;
}

.chooseCoverUserDiv {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: var(--app-color-light);
  //height: calc(var(--vh, 1vh) * 100);
}

.divBtn {
  //padding-top: 10px;
  position: relative;
  height: 80px;
}

.btnSubmit {
  padding-top: 10px;
  float: right;
}