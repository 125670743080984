@import '../../Theme';

.div-customer-information-header {

    h6 {
        font-weight: 600;
        font-size: 22px;
    }

    .span_no_show {
        margin-right: 25px;
    }
    
    .span_no_show, .span_cancelled{   
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.71;
        color: #1d2b58;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal
    }
    
    .noshow--cancelled {
        margin-right: 80px;
        padding-top: 8px;
        text-align: left;
    }

    .MuiSvgIcon-fontSizeSmall {
        font-size: 10px;
    }
    
    .noshow--icon {
        fill:#dfb032;
    }
    
    .cancelled--icon {
        fill:#ff0000;
    }
    
    .MuiDivider-root {
        height: 2px;
        list-style-type: none;
        background-color: #979797;
    }

    .MuiFormControl-root {
        width: 120px;
    }

    .appointmentDatePicker  {
        display: inline;
        div {
            display:inline-flex;
        }
    }

    #spanAppointmentDate {
        font-weight: 600;
        display: inline;
    }

    #date-dialog {
        padding: 0;
    }

    #grid-datepicker {
       padding: 0 5px 0 100px;
    }

    @media (max-width:1400px) {
        #grid-datepicker {
            padding: 0 5px 0 0;
         }
    } 
}