@import '../../Theme';
.conversation-files-holder {
  height: 40px;
  display: flex;
  .image-card--holder {
    .card--image {
      cursor: default;
    }
  }
}


.conversation-input-holder {

  text-align: right;
  padding:0px;
  bottom: 0;
  border-top: 1px solid var(--app-color-light-shade);
  background-color: var(--app-color-light);
  z-index: 8;
  right:0;
  width:100%;


  .conversation-input {
    // padding: 15px 20px 0px;
    padding: 0;
  }

  .conversation-input {

    display: flex;

    button {
      vertical-align: top;
      height: 40px;
    }
    .conversation-input-textarea {
      display:flex; 

      vertical-align: top;
      min-width: 220px;
      position: relative;
      width:100%;

      // padding: 14px 20px 14px 20px;
      padding: 0;
      border-radius: 5px;
      resize: none;
      font-size:16px;
      // border: 1px solid #dddddd;
      background: white;
      textarea {
        
        padding: 10px;
        border: none;
        line-height: 20px;
        border-radius: 5px;
        resize: none;
        background:none;
        font-size:16px;
        background: transparent;
      }
    }
  }
  .conversation-input-spinner {
    margin:10px 20px;
  }
  .conversation-input-error-holder {
    position: relative;
    text-align: left;

    .conversation-input-counter {
      width: 70px;
      text-align: left;
    }
    .conversation-input-error {
      position: absolute;
      top: -30px;
      left: 35px;
    }
  }

}

.conversation--action-container {

  .conversation--action-container--buttons {
    display: flex;
    padding:0 10px;
    position: relative;
    right:0;
    top:5px;
    z-index: 10;
    color: $gray-md;
  
    .MuiButtonBase-root {
      padding: 0
    }
  }
  &--item {
    margin: 0 20px;
    padding: 0 20px;
  }
}
.conversation--action--item {
  margin: 0 5px;
  padding: 0 5px;
  display: flex;
  width:100%;

  &--left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: flex-end;
    & > div {

    }
  }
  &--relative {
    position: relative;
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .conversation-input-holder {
    position: absolute;
    bottom: 95px;
    right: unset;
    width:100%;
    padding:0;

    & > div {
      padding: 15px 20px 0px;
    }

    .conversation-input .conversation-input-textarea {
      min-width: 214px;
    }
  }
  .conversation--action-container {
    display: block;
    margin: 0 0px 0 10px;
    .conversation--action-container--buttons {
      justify-content: space-evenly;
      padding: 0;
      top: 10px;

    }
  }

  .conversation-files-holder {
    .image-card--holder {
      margin-right: 5px;
      .card--image {
      }
    }
  }
}