$theme-colors: (
        'primary': (
                'base': #00acea
        ),
        'primary2': (
                'base': #5a70c7,
                'light': #2158cb
        ),
        'secondary': (
                'base': #084575
        ),
        'primary--disabled': (
                'base': #477ef1,
                'light': #477ef1,
                'dark': #477ef1
        ),
        'background': (
                'base': #e0e0e0,
                'medium': #979797,
                'dark': #424242
        ),
        'button-secondary': (
                'base': #fa716a
        ),
        'black': (
                'base': #000,
                'light': #000,
                'dark': #000
        ),
        'white': (
                'base': #fff,
                'light': #fff,
                'dark': #fff
        ),
        'red': (
                'base': red
        ),
        'error': (
                'base': #d40000
        ),
        'success': (
                'base': #4BB543
        ),
        'warning': (
                'base': #ffc20e
        ),
        'statement': (
                'base': #c1efff
        )
);

@function theme-color($key: 'white', $variant: 'base') {
  $map: map-get($theme-colors, $key);
  @return map-get($map, $variant);
}
