$breakpoint-xs: 750px;
$breakpoint-sm: 1024px;
$bodyHeight: calc(var(--vh));

body {
  overflow-y: hidden !important;
  display: flex;

  #root {
    display: flex;
    flex: 1;
    flex-direction: column;

    .App {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0;
      width: unset;
      height: unset;    
      
      .split-pane {
        display: flex;
        flex: 1;
        position: relative;
      }
    
      .pane-top {    
        flex: unset;

        .MuiAppBar-root {
          position: unset;
          background-color: #03122c !important;
      
          .MuiToolbar-root {
            display: flex;
            flex-direction: row !important;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            min-height: 63px;
            padding: 0;    
            background-color: inherit;
      
            > div {
              display: flex;
              align-items: center;
            }
      
            .logo {
              min-width: 290px;
              padding: 10px 20px;
            }
      
            .tab-heads-cont {
              flex: 1 100%;
              align-items: flex-end;
            }
          
            .account-cont {    
              justify-content: right;  
              min-width: 250px;
              padding: 10px 20px;
            }
          }
        }
      }
    
      .pane-bottom.dashboard {
        flex: 1 100%;
        align-items: stretch;
        height: $bodyHeight;

        .page-cont {
          position: relative;
          display: flex;
          flex: 1;
        
          .module-title {
            text-align: left;
            h1 {
              margin: 20px;
              font-weight: bold;
              font-size: 24px;
              font-family: Roboto, Helvetica, sans-serif;
          
              color: #327dc6;
            }
          }
        }

        .pane-left {    
          display: flex;
          flex: unset;
          flex-direction: column; 
          width: unset;
          
          .multipleLocation {
            #sidebarMenuLocation {
              .modal {
                display: block;
                height: unset;
                left: 295px;
                top: 368px;
                background-color: unset;
                box-shadow: none;
                position: fixed;
                width: 540px;

                .modal-cont {
                  background-color: var(--app-color-light-bright);
                  padding: 20px;
                  border: 1px solid #e0e0e0;
                  border-radius: 10px;
                  
                  .btn-close {
                    top: 10px;
                    right: 10px;
                  }

                  .optionContainer {
                    li:not(:first-child) {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
          }

          .navigation {
            display: flex;
            flex: 1;
            flex-direction: column;
            position: relative;
            transform: translateX(0px);
            min-height: unset;
            width: unset;
            top: unset;
            min-width: 290px;

            .sidebarTitle {
              width: unset;
              height: unset;
              position: unset;
              left: unset;
              margin-top: 20px;    
              margin-left: 20px;
              display: flex;
              align-items: center;
            }

            #nav {

              ul {
                left: unset;
                position: unset;
                padding-top: unset;
                margin-left: -15px;
              }
            }

            #nav.location-aware {
              margin-top: 0;
            }
          }

          @media only screen and (max-width: $breakpoint-sm) {          
            > *:not(.nav-toggle):not(.multipleLocation) {            
              display: none !important;
            }
          }
        }
    
        .pane-right {
          flex: 1 100%;
    
          .container {
            display: flex;
            flex: 1;        
            margin: 0;
            padding: 0;
    
            .page-cont {
              display: flex;
              flex: 1;      
              
              .two-way-cont {
                display: flex;
                flex: 1;

                .two-way {
                  display: flex;
                  flex: 1;
                  align-items: stretch;
  
                  .message--list {
                    min-width: 380px;
                    position: unset;
                    top: unset;
                    // height: unset;
                    display: flex;
                    flex-direction: column;
  
                    .message--list--header {
                      display: flex;
                      flex-direction: column;
    
                      .btn-row {
                        button {
                          display: inline-block;
                          margin: 0 10px 5px 0;
                        }
                      }
                    }
    
                    .message--list--scroll {
                      height: unset;
                      // display: flex;
                      flex-direction: column;
                      flex: 1;
                    }
                  }
                  
                  .message--conversations {
                    display: flex;
                    flex: 1 100%;
                    flex-direction: column;
                    position: relative;
  
                    .message--conversation--holder {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      flex: 1;
                      top: unset;
                      left: unset;
                      width: unset;
                      position: unset;
                    
                      .message--conversation.message--conversation--header {
                        margin-top: 35px;
                        background-color: var(--app-color-light);
                      }

                      .message--conversation[style] {
                        height: unset !important;
                        min-height: 140px !important;
                        position: absolute;
                        top: 110px;
                        bottom: 0;
                        left: 0;
                        right: 0;
                      }
                    }

                    .message--conversation--holder.message--conversation--holder-new.message--conversation--active {
                      .message--conversation:not(.message--conversation--header-new) {
                        position: static;
                      }
                    }

                    .conversation-input-holder,
                    .message--conversation--holder--input {
                        max-height: 150px;
                        min-height: 150px;
                    }
                  }

                  .message--conversation--holder-new {
                    .message--conversation[style] {
                      flex: 1;
                    }  

                    .message--conversation.message--conversation--header-new {
                      max-height: 444px;
                    }   
                  }
                }
              }

              @media only screen and (max-width: $breakpoint-xs) {
                .two-way-cont {
                  .two-way {
                    flex-direction: row-reverse;
                    position: relative;

                    .message--list,
                    .message--conversation--holder {
                      position: absolute;
                      height: 100%;
                    }

                    .message--conversations {
                      .message--conversation--holder {
                        display: none;
                      } 

                      .message--conversation--holder.message--conversation--active {
                        display: flex;
                        justify-content: flex-start;
                        padding: 0;

                        .message--conversation.message--conversation--header {
                          margin-top: unset;

                          .col {
                            .btn-back {
                              padding: 10px 0;
                            }
                          }
                        }

                        .message--conversation.message--conversation--list {
                          top: 75px;
                        }

                        .message--conversation.message--conversation--header-new {
                          position: unset;
                        }

                        .conversation-input-holder {
                          position: unset;
                        }
                      }

                      .message--conversation--holder.message--conversation--holder--input--active {
                        display: flex;
                        width: 100%;
                        position: absolute;
                      }
                    }  
                  }
                }             
              }

              // .online-booking {
              //   display: flex;
              //   flex: 1;        

              //   > div:first-child {
              //     display: flex;
              //     flex: 1;      
              //     flex-direction: column;  

              //     .tabs-cont {                    
              //       display: flex;
              //       flex: 1 100%;      
              //       flex-direction: column;  

              //       .MuiBox-root {                   
              //         display: flex;
              //         flex: 1 100%; 
              //         flex-direction: column;  
              //       }
              //     }
              //   }
              // }
            }
          }
        }
      }
    }
  }
}

.table-responsive {
  .InovuaReactDataGrid {
    // .InovuaReactDataGrid__header {
    //   > div:first-child {
    //     display: none;
    //   }

    //   > div:last-child {
    //     display: flex;
    //   }
    // }
    // .actions-column:first-child {
    //   display: none;
    // }

    // .actions-column:last-child {
    //   display: flex;
    // }
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  body {
    #root {
      .App {
        .pane-top {
          .MuiAppBar-root {  
            .MuiToolbar-root {
              .tab-heads-cont,
              .account-cont {
                display: none;
              }
            }
          }	
        }
      }
    }
  }  

  .table-responsive {
    overflow: auto;
    
    .InovuaReactDataGrid {
      min-width: 780px;

      // .InovuaReactDataGrid__header {
      //   > div:first-child {
      //     display: none;
      //   }
  
      //   > div:last-child {
      //     display: flex;
      //   }
      // }

      // .actions-column:first-child {
      //   display: flex;
      // }

      // .actions-column:last-child {
      //   display: none;
      // }
    }
  }

  .online-booking .tabs-cont {
    position: static;
  }
  
  #root, .App, .split-pane:not(.pane-left), .pane-right > .container, .page-cont,
  .registrationsection, .form-status, .patient-forms,
  .online-booking .insurance-data
  {
    width: 100% !important;
  }
}

@media only screen and (max-width: $breakpoint-sm) {  
  body {
    #root {
      .App {
        .pane-top {        
          .MuiAppBar-root {
            .MuiToolbar-root {
              flex-direction: column !important;
              position: relative;
        
              .logo {
                order: 1;
                display: block;
                float: left
              }
        
              .tab-heads-cont {
                order: 2;
                clear: both;
              }
        
              .account-cont {
                order: 0;
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }	
        }
      }
    }
  }

  body #root .App .pane-bottom.dashboard .pane-left .multipleLocation #sidebarMenuLocation .modal {
    left: 170px;

    .optionListContainer {
      width: 100%;
    }
  }
}
