@import '../../Theme';

.TwoWayModalLocationSelect {
  .search-wrapper {
    position: relative;

    & > span {
      position: absolute;

    }
  }

  #search_input {

  }

  input[disabled] {
    background: none;
    padding: 0;
    cursor: pointer;
  }

  .MuiPaper-root {
    max-width: 500px;
    width: 500px;
    height: 350px;

  }

  .MuiDialog-paperScrollPaper {
    max-height: 500px;
    height: 350px;
  }

  .MuiDialog-container {
    .optionLListContainer {
      .optionContainer {
        white-space: normal;
        line-break: anywhere;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .TwoWayModalLocationSelect {
    .search-wrapper {
      & > span {
        text-overflow: ellipsis;
        width: 190px;
        overflow: hidden;
        display: block;
      }
    }
  }
}