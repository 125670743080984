@import '../../Theme';

.message--conversation--holder {

  .btn-close {
    z-index: 900;
    position: absolute;
    right: 0px;
    top: 0;
  }

  &.message--conversation--holder-new {
    height: auto;
    top: 21px;
    .row__start{
      flex-flow: wrap;
    }

    .row__start:first {
      justify-content: space-between;
    }

    button.btn-showall {
      padding: 0px 7px !important;
      margin: 0 0 0 16px;
      font-size: 10px !important;
    }

    h1 {
      width: auto;
    }

    .message--location-badge {
      position: relative;
      align-self: center;
      bottom: -4px;
      margin-bottom: 0px;
    }

    .message--conversation--refresher {

      &--spinner {
        display: inline-flex;
        transform: scale(0.4);
        top: -12px;
        position: absolute;
        vertical-align: middle;
        margin: 5px 0;
        width: 25px;
        left: -22px;
      }
    }

    .patientResultItem--holder {
      position: relative;

      .patientResultItem {
        position: relative;

        .message--conversation--deny {
          color: var(--app-color-danger);
          position: absolute;
          top: 2px;
        }

        .message--conversation--added {
          color: var(--app-color-green);
          position: absolute;
          top: 2px;
        }

        .message--conversation--refresher--spinner {
          top: -16px;
          left: -13px;
          opacity: 1;
          width: 50px;
          height: 50px;
        }
     
      }
    }

    .message--conversation {
      overflow-y: scroll;
    }

    .message--conversation--header {
      .row__start {
        flex-wrap: wrap;

        h1 {
          margin-top: 15px;
        }
      }

      p {
        margin-bottom: 25px;
        display: block;
      }
    }

    height: 100%;

    button {
      font-size: 15px !important;
      padding: 0;
    }

    .message--search {
      position: relative;

      &--notify--counts {
        display: inline-block;
        height: 20px;
        position: relative;
        top: 9px;
      }

      &--input {
        width: auto;
        border: none;
        background: var(--app-color-light);
        padding-left: 0;

        border: 1px solid #ddd;
        border-radius: 0.3em;
        box-sizing: border-box;
        padding: 10px 15px;
        outline: none;
        margin-right: 5px;
        background: white;
        font-weight: 300;
        font-size: 74%;
      }

      &--status {
        height: 18px;
        position: relative;
        display: inline-block;
        margin: 12px 3px;
      }

      &--results {
        margin: 0px;
        border-radius: 5px;
        background-color: var(--app-color-light-bright);
        position: relative;
        z-index: 10;
        box-shadow: 5px 10px 15px rgba(0, 0, 0, .1);
        position: absolute;
        top: 180px;
        left: 10px;
        min-width: 300px;
        max-height: 300px;
        overflow: scroll;


        p {
          padding: 10px;
          margin-bottom: 0px;
          opacity: .5;
        }

        .row {
          padding: 10px;

          margin: 0;
          cursor: pointer;

          &:hover {
            background-color: var(--app-color-primary-light);
          }


        }

        div {
          margin-right: 20px;
        }

      }

      &--smsTo--holder {
        margin: 0 20px;
        flex-wrap: wrap;
        padding-top: 15px;
      }

      &--smsTo-error-holder {
        margin-left: 20px;

      }

      &--smsTo {
        padding: 10px 30px;
        margin-right: 15px;
        margin-bottom: 5px;
        border: 1px solid var(--app-color-primary);
        color: var(--app-color-dark-shade);
        font-size: 80%;
        border-radius: 20px;
        position: relative;
        cursor: pointer;

        span {
          display: none;
        }

        &:hover {
          background-color: var(--app-color-primary);
          color: var(--app-color-light-bright);

          span {
            display: block;
            font-size: 20px;
            right: 4px;
            font-weight: bold;
            top: 7px;
            background-color: var(--app-color-primary);
            color: var(--app-color-light-bright);
            border-radius: 50%;
            position: absolute;
            padding: 0px 2px;
          }
        }
      }
    }

  }
}

.message--conversation--invalid
{
  color: var(--app-color-danger);
  font-weight: bold;
}

@media only screen and (max-width: $breakpoint-xs) {
  .message--conversation--holder-new {
    .message--conversation--header-new {
      padding-left: 40px;
      padding-right: 40px;
      overflow: scroll;
      max-height: 375px;
    }

    .row {
      flex-direction: column;
    }

    .btn-back {
      display: inline-block;
      padding: 30px;
      padding-left: 0px;
      padding-right: 100px;
      font-size: 30px;
      transform: rotate(90deg);
      top: 45px;
      left: -55px;
    }

    .conversation-input-holder {
      bottom: 263px;
      width: 100%;
      padding: 0;
      position: absolute;
      width: 100%;
      .conversation-input {
        padding: 15px 20px 0px;
      }
    }
  }
  .message--conversation--holder.message--conversation--holder-new {
    .message--location-badge {
      align-self: normal;
      bottom: 0px;
      margin-bottom: 10px;
    }
  }

}

@media only screen and (max-width: $breakpoint-sm) {

  .message--conversation--holder {
    &.message--conversation--holder-new {
      top: 15px;

      .patientResultItem--holder.row{
        padding-left:30px;
        .patientResultItem {
          padding-left:30px;
          .message--conversation--deny, .message--conversation--added, .message--conversation--refresher--spinner {
            left:-20px;
          }
        }
      }

      input {
        font-size: 100%;
        margin: 0;
        padding: 5px;
      }

      .message--search {
        &--results {
          width: 100%;
          margin: 0;
          left: 0;
          border-radius: 0;
          position: absolute;
          height: 300px;
          z-index: 20;
          overflow-y: scroll;
        }

        &--notify--counts {
          top: -5px;
        }
      }

      .message--conversation--header {

        .row__start, p {
          flex-wrap: wrap;
          max-width: 300px;
        }
      }

      button {

        &.btn-clear {
          display: block;
          width: 100%;
          margin-top: 10px;
          padding: 15px 25px;

        }
      }

      .message--search {
        &--smsTo--holder {
          padding-top: 10px;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        &--smsTo {
          font-size: 100%;
          margin-right: 5px;
          padding: 10px 35px;

          &:hover {
            span {
              top: 8px;
            }
          }

          &--holder {
            padding-top: 10px;
            overflow-y: scroll;
          }
        }

        &--input {
          border: 1px solid rgba(0, 0, 0, .4);
          border-color: var(--app-color-primary);
          padding: 10px 25px;
          margin: 10px 5px 5px 0;
          border-radius: 5px;
          font-weight: normal;
          width: 100%;

          &::placeholder {
            color: var(--app-color-primary);
            opacity: .5;
            font-weight: normal;
          }
        }
      }

    }

  }

}

@media only screen and (max-width: $breakpoint-xs) {
  .message--conversation--holder {
    &.message--conversation--holder-new {
      top: 64px;

      .message--search--results {
        bottom: 273px;
        top: revert;
      }
    }
  }
}