@import 'Theme';

.version {
  position: fixed;
  left: 20px;
  bottom: 10px;
  z-index: 9999;
  color: $gray-md-dark;
}

.App {
  /*
  text-align: center;
   */
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .App-header {
    background-color: $primary;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: $primaryHighlight;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-tour {
  background-color: #3498db;
  border-radius: 8px;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
 // margin: 4px 2px;
  cursor: pointer;
}